/**
 * @author Irakli Kandelaki
 */

/**
 * @summary Scroll to an element on native scroll.
 */

export default (el: HTMLElement, offset: number): number => {
    const elTop = el.offsetTop - offset;
    return elTop;
}