/**
 * @author Irakli Kandelaki
 */

import { handleResize } from "./resizeHandler";

const { windowHeight} = handleResize();

export function isInViewport(el: HTMLElement, offset: number): boolean {
    const elTop: number = el.getBoundingClientRect().top;
    const isElVisible: boolean = elTop - windowHeight.value <= offset ? true
        : false;
    return isElVisible;
}
